
import { Vue, Options } from "vue-class-component";
import { DatePicker } from "v-calendar";
import { dateToDateString } from "@/app/infrastructures/misc/Utils";

@Options({
  emits: [
    "select",
    "update:startDate",
    "update:endDate",
    "dayClick",
    "cancelRangePick",
    "validateSelectPerMonth"
  ],
  components: {
    DatePicker
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    startDate: {
      default: null,
      type: [Date, String]
    },
    endDate: {
      default: null,
      type: [Date, String]
    },
    placeholder: {
      default: "Pilih Range Tanggal",
      type: String
    },
    default: {
      default: "custom",
      type: String
    },
    minDate: {
      default: null,
      type: [Date, String]
    },
    maxDate: {
      default: null,
      type: [Date, String]
    },
    customTotalMaxDate: {
      default: 0,
      type: Number
    },
    overlayStyle: {
      default: null,
      type: String
    },
    hideOptions: {
      default: false,
      type: Boolean
    },
    showOptions: {
      default: "",
      type: String
    },
    isOptionDisabled: {
      default: false,
      type: Boolean
    },
    isRange: {
      default: true,
      type: Boolean
    },
    isResetOutside: {
      default: false,
      type: Boolean
    },
    customDefaultValue: {
      default: false,
      type: Boolean
    },
    advanceStyle: {
      default: {
        width: "550px",
        fontSize: "text-lg"
      },
      type: Object
    },
    isCloseDateAfterSelect: {
      default: false,
      type: Boolean
    },
    isConvertName: {
      default: false,
      type: Boolean
    },
    positionX: {
      default: 0, //px
      type: Number
    },
    isMounted: {
      default: true,
      type: Boolean
    },
    leftPosition: {
      default: true,
      type: Boolean
    }
  },
  watch: {
    startDate() {
      if (!this.props.startDate) {
        this.dates.start = null;
      }
    },
    endDate() {
      if (!this.props.endDate) {
        this.dates.end = null;
      }
    }
  }
})
export default class TimeRangePicker extends Vue {
  picker: any = "";
  refs: any = "";
  preventClose = false;
  props: any = this.$props;
  showCalendar = false;
  dates: any = { start: null, end: null };
  startDate: any = null;
  endDate: any = null;
  attributes = [{ key: "today", dot: "green", dates: new Date() }];

  mounted() {
    if (this.props.isMounted) {
      this.onSelectOption(this.props.default);
    }
    this.onSetPickerRefs();
    this.refs = this.$refs;
    if (this.props.minDate || this.props.maxDate) {
      this.setLimitDateRange({
        min: this.props.minDate,
        max: this.props.maxDate
      });
    }
    if (this.props.startDate && this.props.endDate) {
      this.dates = {
        start: this.props.startDate,
        end: this.props.endDate
      };
      this.$emit("select", [this.dates.start, this.dates.end]);
    }
  }

  onSetPickerRefs() {
    const picker: any = this.$refs.picker;
    this.picker = {
      ...picker,
      value_: { start: this.props.startDate, end: this.props.endDate }
    };
  }

  setDateFormat(date: any) {
    return new Date(new Date(date).setUTCHours(0));
  }
  onClick() {
    if (!this.props.disabled) {
      this.showCalendar = !this.showCalendar;
    }
  }
  onDateSelected() {
    this.selected = this.$t("custom");
    let dates = [this.dates.start, this.dates.end];
    dates = [
      dates[0]
        ? new Date(
            new Date(
              new Date(
                new Date(dates[0]).setUTCDate(new Date(dates[0]).getDate())
              ).setUTCHours(0)
            ).setUTCMonth(new Date(dates[0]).getMonth())
          )
        : null,
      dates[1]
        ? new Date(
            new Date(
              new Date(
                new Date(dates[1]).setUTCDate(new Date(dates[1]).getDate())
              ).setUTCHours(0)
            ).setUTCMonth(new Date(dates[1]).getMonth())
          )
        : null
    ];
    this.$emit("update:startDate", dates[0]);
    this.$emit("update:endDate", dates[1]);
    this.selected !== this.$t("hari ini") && this.$emit("select", dates, true);
    if (this.props.isCloseDateAfterSelect) this.onClickOutside();
  }
  get options() {
    return [
      this.$t("custom"),
      this.$t("hari ini"),
      this.$t("kemarin"),
      this.$t("3 hari terakhir"),
      this.$t("7 hari terakhir"),
      this.$t("14 hari terakhir"),
      this.$t("30 hari terakhir"),
      this.$t("31 hari terakhir"),
      this.$t("satu bulan"),
      this.$t("bulan ini"),
      this.$t("tahun ini")
    ];
  }
  convertOption(option: string) {
    if (!this.props.isConvertName) return option;
    if (option === this.$t("custom")) return this.$t("Tentukan Tanggal");
    if (option === this.$t("7 hari terakhir")) return this.$t("Minggu Kemarin");
    return option;
  }
  selected = "";
  isSelected(value: string) {
    return value === this.selected;
  }
  onCustom = true;
  onSelectOption(option: any, isFetch = true) {
    this.selected = option;
    let dates: any = [];
    const customDefaultValue =
      this.props.customDefaultValue &&
      this.props.startDate &&
      this.props.endDate;

    switch (this.selected) {
      case this.options[0]:
        dates = customDefaultValue
          ? [
              this.setDateFormat(this.props.startDate),
              this.setDateFormat(this.props.endDate)
            ]
          : [];
        this.dates = {
          start: customDefaultValue ? dates[0] : null,
          end: customDefaultValue ? dates[1] : null
        };
        this.picker.move(new Date());
        this.$emit(
          "select",
          customDefaultValue ? dates : [null, null],
          isFetch
        );
        break;
      case this.options[1]:
        dates = [
          new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1
            ).setUTCHours(0)
          ),
          new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1
            ).setUTCHours(0)
          )
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[2]:
        dates = [
          new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ).setUTCHours(0)
          ),
          new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ).setUTCHours(0)
          )
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[3]:
        dates = [
          new Date(new Date(new Date().setDate(new Date().getDate() - 2))),
          new Date(new Date())
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[4]:
        dates = [
          new Date(new Date(new Date().setDate(new Date().getDate() - 6))),
          new Date(new Date(new Date().setDate(new Date().getDate())))
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[5]:
        dates = [
          new Date(new Date(new Date().setDate(new Date().getDate() - 13))),
          new Date(new Date())
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[6]:
        dates = [
          new Date(new Date(new Date().setDate(new Date().getDate() - 29))),
          new Date(new Date(new Date().setDate(new Date().getDate())))
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[7]:
        dates = [
          new Date(new Date(new Date().setDate(new Date().getDate() - 30))),
          new Date(new Date(new Date().setDate(new Date().getDate())))
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[8]:
        dates = [
          this.$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[9]:
        dates = [
          this.$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD")
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      case this.options[10]:
        dates = [
          this.$moment()
            .startOf("year")
            .format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD")
        ];
        this.dates = { start: dates[0], end: dates[1] };
        this.picker.move(dates[0]);
        this.$emit("select", dates, isFetch);
        break;
      default:
        this.dates = { start: null, end: null };
        this.picker.move(new Date());
        this.$emit("select", [null, null], isFetch);
        break;
    }
  }
  formatDateWithoutTime(date: any) {
    return date !== null ? this.$moment(date).format("DD MMM YYYY") : "";
  }

  isCursorInsideCalendar = false;

  onEnterCalendar(val: boolean) {
    this.isCursorInsideCalendar = val;
  }

  rangeDate: any = {
    min: null,
    max: null
  };
  setLimitDateRange(params: { min?: any; max?: any }) {
    this.rangeDate = {
      min: params.min ? new Date(params.min) : null,
      max: params.max ? new Date(params.max) : null
    };
  }
  dateToDateString(date: any) {
    return dateToDateString(date);
  }
  dayClick(e: any) {
    this.onCustomDayClick(e);
    this.$emit("dayClick", e);
    this.selected = this.$t("custom");
  }
  isDragRange = false;
  onCustomDayClick(e: any) {
    // check is date from click more than min date
    const isMoreMin =
      new Date(e.date).getTime() >=
      new Date(new Date(this.rangeDate.min).setHours(0, 0, 0, 0)).getTime();
    // check is date from click less than max date
    const isLessMax =
      new Date(e.date).getTime() <= new Date(this.rangeDate.max).getTime();
    const customRange =
      this.props.customTotalMaxDate &&
      isMoreMin &&
      (this.isDragRange || this.isOnlySetMax ? isLessMax : true);

    if (customRange) {
      if (this.isOnlySetMax) this.onOnlySetMax(e);
      else this.onOnlyCustomRange(e);
    }
  }

  // when click once, will only set max range dates
  isOnlySetMax = false;
  onOnlySetMax(e: any) {
    this.dates = {
      start: this.dates.start,
      end: new Date(e.date)
    };
    // assign value dragValue to null is equals only changes max date
    this.picker.dragValue = null;
    this.picker.value_ = this.dates;
  }

  onOnlyCustomRange(e: any) {
    const maxDate = new Date(e.date).setDate(
      new Date(e.date).getDate() + this.props.customTotalMaxDate
    );

    // set range date with max date is total = this.props.customTotalMaxDate from start click
    this.setLimitDateRange({
      min: this.isDragRange ? new Date() : e.date,
      max: this.isDragRange ? null : maxDate
    });
    this.isDragRange = !!this.picker.dragValue;
  }

  dayFocusOut() {
    if (!this.isCursorInsideCalendar) {
      this.$emit("cancelRangePick", "");
    }
  }

  onClickOutside() {
    if (this.preventClose) {
      this.preventClose = false;
    } else {
      // reset range date to default
      if (this.props.isResetOutside) {
        this.picker.dragValue = null;
        this.isDragRange = false;
        this.setLimitDateRange({
          min: new Date(new Date().setHours(0, 0, 0, 0)),
          max: null
        });
      }
      this.showCalendar = false;
      this.$emit("validateSelectPerMonth");
    }
  }
}
